<template>
  <Headline v-if="showTitle" type="h1" :classes="titleBackground ? 'py-10 has-background h3 text-center' : 'h3 text-center'" :text="title"/>
  <div>
    <Section v-for="section of this.sections" :key="section.id" :background="section.background" :elements="section.elements" :id="section.id"/>
  </div>
</template>

<script>
import {defineAsyncComponent} from "vue";
import ApiService from "@/services/apiService";
import eventService from "@/services/eventService";
import qs from "qs";
import Meta from '@/services/metaService'

const Section = defineAsyncComponent(() => import('@/layout/Section'));
const Headline = defineAsyncComponent(() => import('@/components/Headline'));

export default {
  name: "Page",
  data() {
    return {
      content: null,
      locale: '',
      sections: [],
      title: '',
      showTitle: false,
      description: '',
      ogImage: null,
      titleBackground: false
    }
  },
  components: {
    Section,
    Headline
  },
  async created() {
    this.rerender()
  },
  mounted() {
    eventService.$on('lang-switch', () => {
      this.fetchPage();
    });
  },
  methods: {
    fetchMeta() {
      let queryOptions = this.getQueryOptions(this.$root.$i18n.locale);
      queryOptions.populate.ogImage = {populate: '*'}
      let query = qs.stringify(queryOptions);
      ApiService.get(`allgemein?${query}`).then(res => {
        let data = res.data.data;
        if(data) {
          //let favicon = ApiService.getBaseUrl() + data.attributes.Favicon.data.attributes.url
          Meta.createMeta({name: 'name', content: 'description'}, {name: 'content', content: this.description })
          Meta.createMeta({name: 'property', content: 'og:title'}, {name: 'content', content: this.title})
          Meta.createMeta({name: 'property', content: 'og:description'}, {name: 'content', content: this.description})
          Meta.createMeta({name: 'property', content: 'og:image'}, {name: 'content', content: this.ogImage ? ApiService.getBaseUrl() + this.ogImage.attributes.url : ApiService.getBaseUrl() + data.attributes.ogImage.data.attributes.url})
          Meta.createMeta({name: 'property', content: 'og:url'}, {name: 'content', content: window.location.href})
          Meta.createMeta({name: 'property', content: 'og:site_name'}, {name: 'content', content: data.attributes.Name})
          Meta.createMeta({name: 'name', content: 'robots'}, {name: 'content', content: 'index, follow'})
          Meta.createLink('canonical', window.location.href)
        }
        document.title = this.title + " | " + data.attributes.Name
      })
    },
    fetchPage() {
      this.$wait.start("page");
      let queryOptions = this.getQueryOptions(this.$root.$i18n.locale);
      queryOptions.filters = {Slug: {$eq: this.$route.fullPath}}
      queryOptions.populate.Content = {populate: '*'};
      queryOptions.populate.ogImage = {populate: '*'};
      let query = qs.stringify(queryOptions)
      ApiService.get(`seitens?${query}`).then(res => {
        let data = res.data.data[0];
        if(data) {
          this.content = data.attributes.Content
          this.getSections()
          this.showTitle = data.attributes.showTitle
          this.title = data.attributes.name
          this.description = data.attributes.meta;
          this.ogImage = data.attributes.ogImage.data;
          this.titleBackground = data.attributes.titleBackground;
          this.fetchMeta();
          this.$wait.end("page")
        }
      });
    },
    getSections() {
      let sectionCount = -1;
      let sections = [];
      for (let element of this.content) {
        if(element["__component"] === "layout.section") {
          let section = {
            id: element.id,
            background: element.background,
            elements: []
          }
          sections.push(section);
          sectionCount++;
        } else {
          if(sectionCount >= 0) {
            sections[sectionCount].elements.push(element);
          }
        }
      }

      this.sections = sections;
    },
    getQueryOptions(locale) {
      return {
        populate: {
        },
        sort: [],
            filters: {},
        locale: [locale],
      }
    },
    rerender(){
      this.$nextTick(() => {
        this.fetchPage()
      })
    }
  },
  unmounted() {
    Meta.destroyMeta({name: 'name', content: 'description'})
    Meta.destroyMeta({name: 'name', content: 'robots'})
    Meta.destroyMeta({name: 'property', content: 'og:title'})
    Meta.destroyMeta({name: 'property', content: 'og:description'})
    Meta.destroyMeta({name: 'property', content: 'og:image'})
    Meta.destroyMeta({name: 'property', content: 'og:url'})
    Meta.destroyMeta({name: 'property', content: 'og:site_name'})
    Meta.destroyLink('canonical')
  }
}
</script>

<style scoped lang="scss">
  .section-inner {

    max-width: var(--section-width);
  }
</style>
